<template>
  <footer
    class="dark:bg-zinc-900 bg-white color-secondary flex flex-col items-center py-2 px-3"
    ref="footer"
  >
    <ul
      class="inline-flex flex-wrap justify-around gap-3 text-lg  underline "
    >
      <li>
        <o-select
          class="text-black dark:"
          :aria-label="t('Language')"
          v-model="locale"
          :placeholder="t('Select a language')"
        >
          <option
            v-for="(language, lang) in langs"
            :value="lang"
            :key="lang"
            :selected="isLangSelected(lang)"
          >
            {{ language }}
          </option>
        </o-select>
      </li>
      <li>
        <router-link :to="{ name: RouteName.ABOUT }">{{
          t("About")
        }}</router-link>
      </li>
      <li>
        <router-link :to="{ name: RouteName.TERMS }">{{
          t("Terms")
        }}</router-link>
      </li>
      <li>
        <a
          rel="external"
          hreflang="en"
          href="https://framagit.org/framasoft/mobilizon/blob/main/LICENSE"
        >
          {{ t("License") }}
        </a>
      </li>
      <li>
        <router-link :to="{ name: RouteName.PRIVACY }">{{
            t("Privacy policy")
          }}</router-link>
      </li>
      <li>
        <a href="#navbar">{{ t("Back to top") }}</a>
      </li>
    </ul>
    <div class="text-center flex-1 pt-2">
      <i18n-t
        tag="span"
        keypath="Powered by {mobilizon}. © 2018 - {date} The Mobilizon Contributors - Made with the financial support of {contributors}."
      >
        <template #mobilizon>
          <a
            rel="external"
            class="underline"
            href="https://joinmobilizon.org"
            >{{ t("Mobilizon") }}</a
          >
        </template>
        <template #date
          ><span>{{ new Date().getFullYear() }}</span></template
        >
        <template #contributors>
          <a
            rel="external"
            class="underline"
            href="https://joinmobilizon.org/hall-of-fame"
            >{{ t("more than 1360 contributors") }}</a
          >
        </template>
      </i18n-t>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { saveLocaleData } from "@/utils/auth";
import { loadLanguageAsync } from "@/utils/i18n";
import RouteName from "../router/name";
import langs from "../i18n/langs.json";
import { watch } from "vue";
import { useI18n } from "vue-i18n";

const { locale, t } = useI18n({ useScope: "global" });

watch(locale, async () => {
  if (locale) {
    console.debug("Setting locale from footer");
    await loadLanguageAsync(locale.value as string);
    saveLocaleData(locale.value as string);
  }
});

const isLangSelected = (lang: string): boolean => {
  return lang === locale.value;
};
</script>

<style lang="scss">
footer > ul > li {
  margin: auto 0;
}
</style>
